import {
  AxesHelper,
  BufferAttribute,
  BufferGeometry,
  IcosahedronBufferGeometry,
  PerspectiveCamera,
  Points,
  PointsMaterial,
  Raycaster,
  Scene,
  SphereGeometry,
  TextureLoader,
  Vector2,
  WebGLRenderer,
} from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

const threeSetup = () => {
  const initScene = new Scene()
  const count = 30000
  const distance = 2000

  const textureLoader = new TextureLoader()
  var diamondTexture = textureLoader.load('fragempty.png')
  var diamondTextureTest = textureLoader.load('frag.png')

  /*     scene.add(new AxesHelper())
   */
  const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1000)

  /*             camera.position.z = 61
        camera.position.x = 527
        camera.position.y = 315 */

  camera.position.z = 543
  camera.position.x = 181
  camera.position.y = 229
  camera.aspect = window.innerWidth / window.innerHeight

  initScene.add(camera)

  const points = new Float32Array(count * 3)
  for (let i = 0 ; i < points.length ; i++) {
    points[i] = (Math.random() - 0.5) * distance
  }

  const geometry = new BufferGeometry()

  geometry.setAttribute('position', new BufferAttribute(points, 3))
  const pointMaterial = new PointsMaterial({
    size: 3.5,
    map: diamondTexture,
    alphaTest: 0.01,
    transparent: true,
  })
  const pointsObject = new Points(geometry, pointMaterial)
  initScene.add(pointsObject)

  // Objects
  const sphereGeometry = new SphereGeometry(25, 40, 25)
  const sphereGeometry2 = new SphereGeometry(40, 45, 35)
  const sphereGeometry3 = new SphereGeometry(150, 120, 100)
  const sphereGeometry4 = new SphereGeometry(130, 120, 100)
  const sphereGeometry5 = new SphereGeometry(350, 320, 300)

  // Materials
  const material = new PointsMaterial({
    size: 2.5,
    map: diamondTexture,
    color: 0xffffff,
    alphaTest: 0.01,
    transparent: true,
  })
  const material2 = new PointsMaterial({
    size: 5,
    map: diamondTexture,
    color: 0x28c0a1,
    alphaTest: 0.01,
    transparent: true,
  })

  const material3 = new PointsMaterial({
    size: 5,
    map: diamondTexture,
    color: 0xfe708e,
    alphaTest: 0.01,
    transparent: true,
  })

  const material4 = new PointsMaterial({
    size: 5,
    map: diamondTexture,
    color: 0x823eff,
    alphaTest: 0.01,
    transparent: true,
  })

  const material5 = new PointsMaterial({
    size: 5,
    map: diamondTexture,
    color: 0xff9060,
    alphaTest: 0.01,
    transparent: true,
  })

  // Spheres
  const sphere = new Points(sphereGeometry, material)
  const sphere2 = new Points(sphereGeometry3, material2)
  const sphere3 = new Points(sphereGeometry2, material3)
  const sphere4 = new Points(sphereGeometry4, material4)
  const sphere5 = new Points(sphereGeometry5, material5)

  // Spheres Position
  sphere4.position.x = -370
  sphere4.position.z = -140
  sphere5.position.y = -70
  sphere5.position.x = -225
  sphere5.position.y = 170
  sphere5.position.z = 600
  sphere2.position.y = 150
  sphere2.position.x = 300
  sphere.position.x = 370
  sphere3.position.x = 200
  sphere3.position.z = 200
  sphere2.position.z = -200

/*   initScene.add(sphere, sphere2, sphere3, sphere4, sphere5)
 */
  const renderer = new WebGLRenderer({
    antialias: true,
  })

  renderer.setSize(window.innerWidth, window.innerHeight)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  renderer.domElement.style.position = 'fixed'
  renderer.domElement.style.zIndex = '0'
  document.body.appendChild(renderer.domElement)
  /*     renderer.render(scene, camera)
   */

  const controls: any = new OrbitControls(camera, renderer.domElement)
  controls.screenSpacePanning = false
  controls.enableDamping = true
  controls.dampingFactor = true
  controls.minDistance = 500
  controls.maxDistance = 1000
  controls.enableZoom = false
  controls.autoRotate = true
  controls.autoRotateSpeed = 0.25

  const raycaster = new Raycaster()
  const pointer = new Vector2()

  function onPointerMove(event: { clientX: number , clientY: number }) {
    // calculate pointer position in normalized device coordinates
    // (-1 to +1) for both components

    pointer.x = (event.clientX / window.innerWidth) * 2 - 1
    pointer.y = -(event.clientY / window.innerHeight) * 2 + 1
  }

  window.addEventListener('pointermove', onPointerMove)

  window.addEventListener( 'resize', onWindowResize, false );

function onWindowResize(){

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    renderer.setSize( window.innerWidth, window.innerHeight );

}

  function tick() {
    // update the picking ray with the camera and pointer position
    // raycaster.setFromCamera(pointer, camera)

    // calculate objects intersecting the picking ray
    // const intersects = raycaster.intersectObjects(initScene.children)

    // for (let i = 0 ; i < intersects.length ; i++) {
    //   if (intersects.length === 6 || intersects.length === 12) {
    //     (intersects[i].object as any).material.map = diamondTextureTest
    //   }
    // }

    renderer.render(initScene, camera)
    controls.update()
    requestAnimationFrame(tick)
  }

  tick()

  var lastScrollTop = 0

  const initSpheres = { sphere, sphere2, sphere3, sphere4, sphere5 }

  return { initSpheres, initScene }
}

export default threeSetup