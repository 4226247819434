import type { NextPage } from 'next'
import styles from '../styles/Home.module.css'
import threeSetup from '../service/three'
import { useEffect, useState } from 'react'
import NavBar from '../components/navbar'
import { TailSpin } from 'react-loader-spinner'
import Head from 'next/head'

const Home: NextPage = () => {
  const [colorsState, setColorsState] = useState(['C4C4C4', '823EFF', 'FE708E', '28C0A1', 'FF9060'])
  const [colorActive, setColorActive] = useState([true, false, false, false, false])
  const white = 'FFFFFF'

  useEffect(() => {
    threeSetup()
    const interval = setInterval(() => {
      // const colors = colorsState
      // const lastColor = colors.pop()
      // colors.unshift(lastColor as string)
      // setColorsState(colors.map((v) => v))
      const actives = colorActive
      const lastActive = actives.pop()
      actives.unshift(lastActive as boolean)
      setColorActive(actives.map((v) => v))
      console.log({ actives, colorActive })
      // forceUpdate()
    }, (60 / 67) * 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className={styles.container}>
        <Head>
          {/* <link rel="shortcut icon" href={favicon} /> */}
          <title>yakowverse</title>
          <meta property="og:title" content="yakowverse" key="title" />
{/*           <meta httpEquiv="X-UA-Compatible" content="IE=edge"  />
          <meta name="viewport" content="initial-scale=1.0, width=device-width" />
 */}          <link rel="shortcut icon" href="../images/icons/favicon.ico" />
        </Head>
      <div className={styles.containNavBar}>
        <NavBar />
      </div>
      <div className="sm:flex" id={styles.containerAbsolute}>
        <div id={styles.spinnerMobile}>
          <TailSpin color={`#${colorsState[colorActive.indexOf(true)]}`} width={70} height={70} />
        </div>
        <div className="sm:pl-10">
          <h1 className="text-5xl sm:text-7xl mb-10">
            {' '}
            <span style={{color : `#${colorActive[0] ? colorsState[0] : white}`}}>Y</span>
            <span style={{color : `#${colorActive[1] ? colorsState[1] : white}`}}>A</span>
            <span style={{color : `#${colorActive[2] ? colorsState[2] : white}`}}>K</span>
            <span style={{color : `#${colorActive[3] ? colorsState[3] : white}`}}>O</span>
            <span style={{color : `#${colorActive[4] ? colorsState[4] : white}`}}>W</span>
            <span className="text-base">©</span> IS IN PROGRESS...
          </h1>
          <p>Our website is currently under construction</p>
          <p>We are working to give you the better experience</p>
        </div>
      </div>
    </div>
  )
}

export default Home
